<template>
  <div class="card-home">
    <div
      class="phyathai-card px-5 pb-10"
      v-if="memberships.length > 0 && isTermsNConsent === 0"
    >
      <div v-for="(membership, index) in domains" :key="index">
        <div v-if="isAvalible(membership)" class="mt-5">
          <div class="card">
            <div v-if="isPending(membership)" class="card-pending white--text">
              <div class="pending-group">
                <div class="msg-status text-16 font-500">
                  สถานะรอการตอบรับจากระบบ
                </div>
                <div class="contract text-12 mt-2">
                  หากไม่ได้รับการอนุมัติภายใน 72 ชม.<br />
                  กรุณาติดต่อเจ้าหน้าที่ Line OA @phyathai3family
                </div>
              </div>
            </div>
            <img
              v-else
              @click="goHome(membership)"
              :src="findImage(membership)"
              class="card-image"
            />
            <div class="information-btn">
              <v-btn
                icon
                class="detail-logo-membership"
                @click="readTerm(membership)"
              >
                <img
                  width="30px"
                  height="30px"
                  :src="detailIcon"
                  alt="detail icon"
                />
              </v-btn>
            </div>
          </div>
        </div>
        <div v-else class="collection-card mt-5 px-5 py-1">
          <div class="d-flex justify-space-between">
            <div class="card-img mt-4">
              <img :src="findIcon(membership)" width="130px" />
            </div>
            <div class="d-flex justify-center">
              <v-btn icon class="detail-logo" @click="readTerm(membership)">
                <img
                  width="30px"
                  height="30px"
                  :src="detailIcon"
                  alt="detail icon"
                />
              </v-btn>
            </div>
          </div>
          <div class="d-flex justify-center">
            <v-btn icon class="plus-logo" @click="goRegister(membership)">
              <img width="25px" height="25px" :src="plusIcon" alt="plus icon" />
            </v-btn>
          </div>
          <div class="card-type text-center mt-5 font-color">
            เพิ่มบัตร
            <span class="font-weight-bold font-color">{{
              findMessage(membership)
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <TermsConsentCard
      v-if="isTermsNConsent === 1"
      :term="termInfo"
      @back="isTermsNConsent = 0"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import TermsConsentCard from "../components/TermsConsentCard";

export default {
  name: "membership",
  components: {
    TermsConsentCard,
  },
  data: () => ({
    // domains: ["be", "prestige", "all-you", "mom-baby"],
    domains: ["be", "all-you", "mom-baby"],
    plusIcon: require("@/assets/icon/plus.png"),
    detailIcon: require("@/assets/icon/detailIcon.svg"),
    befamilyIcon: require("@/assets/img/befamilylogo.png"),
    prestigeIcon: require("@/assets/img/prestigelogo.png"),
    allyouIcon: require("@/assets/img/allyoulogo.png"),
    mombabyIcon: require("@/assets/img/mommyandbabylogo.png"),
    //Membership Image
    befamilyImage: require("@/assets/img/bePhyathai.png"),
    prestigeImage: require("@/assets/img/prestige.png"),
    allyouImage: require("@/assets/img/allyou.png"),
    mommybabyImage: require("@/assets/img/mommy.png"),
    memberships: [],
    isTermsNConsent: 0,
    termInfo: {},
  }),
  computed: {
    ...mapState("Request", ["requestApi"]),
    ...mapState("Auth", ["user"]),
  },
  methods: {
    ...mapActions({
      setonDomain: "Layout/setonDomain",
      setUserProfile: "Auth/setUserProfile",
    }),
    goHome(membership) {
      this.setonDomain(membership);
      if (membership !== "be") {
        const index = this.user.memberships.findIndex(
          (m) => m.domain === membership
        );
        const member = this.user.memberships[0];
        this.user.memberships[0] = this.user.memberships[index];
        this.user.memberships[index] = member;
      }
      this.$router
        .replace({
          path: "/home",
        })
        .catch(() => {});
    },
    goRegister(membership) {
      this.setonDomain(membership);
      this.$router
        .replace({
          path: "/register",
        })
        .catch(() => {});
    },
    async fetchMemberShip() {
      this.$wait.start("app loading");

      const getCard = async (domain) => {
        const membership = await this.requestApi.membership.getMembership({
          domain,
        });
        if (membership.pending_purchase_approval_request) {
          return {
            domain,
            pending: membership.pending_purchase_approval_request,
          };
        }
        const card = await this.requestApi.membershipCard.getMembershipCard({
          domain,
        });
        return {
          domain,
          pending: membership.pending_purchase_approval_request,
          ...card,
        };
      };
      const cardPromises = this.domains.map((domain) => getCard(domain));
      await Promise.allSettled(cardPromises).then((result) => {
        this.memberships = result.map((e) => e.value);
      });
      this.setUserProfile({
        user: {
          memberships: this.memberships.map((e) => (e !== undefined ? e : {})),
        },
      });
      this.$wait.end("app loading");
    },

    async readTerm(domain) {
      try {
        this.$wait.start("app loading");
        const consents = await this.requestApi.consent.getConsent({
          id: domain,
        });
        this.termInfo = consents;
        this.isTermsNConsent = 1;
        // console.log(this.termInfo);
      } catch (err) {
        alert(err);
      } finally {
        this.$wait.end("app loading");
      }
    },

    isAvalible(membership) {
      const isFound = this.memberships.some((e) => e?.domain === membership);
      return isFound;
    },
    isPending(membership) {
      const { pending } = this.memberships.filter(
        (e) => e?.domain === membership
      )[0];
      return pending;
    },
    findImage(membership) {
      const { image } = this.memberships.filter(
        (e) => e?.domain === membership
      )[0];
      return image;
    },
    findIcon(membership) {
      if (membership === "be") {
        return this.befamilyIcon;
      } else if (membership === "prestige") {
        return this.prestigeIcon;
      } else if (membership === "all-you") {
        return this.allyouIcon;
      } else if (membership === "mom-baby") {
        return this.mombabyIcon;
      }
    },
    findMessage(membership) {
      if (membership === "be") {
        return "Be Phyathai 3 Family";
      } else if (membership === "prestige") {
        return "Long Term Prestige";
      } else if (membership === "all-you") {
        return "All You Can Check";
      } else if (membership === "mom-baby") {
        return "Mommy & Baby Club";
      }
    },
  },
  created() {
    this.setonDomain("be");
    this.fetchMemberShip();
  },
};
</script>
<style lang="scss" scoped>
.collection-card {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23757575FF' stroke-width='3' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  width: 350px;
  border-radius: 20px;
  height: 220px;
}

.card {
  position: relative;
  border-radius: 20px;
  height: 220px;
  width: 350px;
}
.card-pending {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: inherit;
  height: inherit;
  width: inherit;
  text-align: center;
}

.msg-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 258px;
  height: 40px;
  background: #000000;
  border-radius: 20px;
}

.information-btn {
  position: absolute;
  top: 0;
  right: 60px;
}

.card-image {
  border: none;
  object-fit: cover;
  border-radius: inherit;
  height: inherit;
  width: inherit;
}

.plus-logo {
  width: fit-content;
  height: fit-content;
  padding: 12px;
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
}

.detail-logo {
  width: fit-content;
  height: fit-content;
  margin: 25px -20px 0 0;
  padding: 5px 15px 5px 15px;
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px 0 0 20px;
}
.detail-logo-membership {
  width: fit-content;
  height: fit-content;
  margin: 25px -290px 0 0;
  padding: 5px 15px 5px 15px;
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px 0 0 20px;
}

.font-color {
  color: #757575;
}
</style>
