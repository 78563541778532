<template>
  <div class="terms-consent">
    <div class="navbar primary--text pa-5">
      <div class="d-flex align-center">
        <div
          class="back-btn mr-3 rounded-circle d-flex align-center justify-center w-40 h-40"
          @click="$emit('back')"
        >
          <img :src="arrowIcon" width="20" />
        </div>
        <div class="primary--text">
          <div class="text-20 font-700 line-h-1">เงื่อนไขและข้อตกลง</div>
          <div class="text-20 font-700">การใช้บัตรสมาชิก</div>
        </div>
      </div>
    </div>
    <div class="card-container my-5 px-5">
      <div class="terms-consent-title primary-gradient--bg pa-5">
        <div class="terms-consent-head white--text">
          <div class="text-18 font-500">เงื่อนไขและข้อตกลงการใช้บัตรสมาชิก</div>
          <div class="line-h-1 text-20 font-700">{{ titleByDomain }}</div>
        </div>
      </div>
      <div class="card-group pb-5 px-5">
        <div class="terms-consent-detail">
          <div class="terms-consent-body py-5">
            <div
              v-html="term.content"
              style="overflow-wrap: break-word"
              class="privilege text-wrap font-500"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "terms-consent",
  components: {},
  props: {
    term: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      arrowIcon: require("@/assets/icon/arrowleft.png"),
    };
  },
  computed: {
    titleByDomain() {
      if (this.term.id === "be") {
        return "Be Phyathai 3 Family";
      } else if (this.term.id === "prestige") {
        return "Long Term Prestige";
      } else if (this.term.id === "all-you") {
        return "All You Can Check";
      } else {
        return "Mommy & Baby Club";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  padding: 0px 1.8rem;
}

.privilege {
  color: #757575;
}
.card-group {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  border-radius: 0px 0px 20px 20px;
}

.terms-consent-body {
  color: #757575;
}

.terms-consent-title {
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  border-radius: 20px 20px 0px 0px;
}
.navbar {
  background: linear-gradient(
    180deg,
    rgba(231 231 231) 50%,
    rgba(115, 192, 136, 0.1) 100%
  );
  border-bottom: 1px solid var(--v-primaryLight-base);
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
}
.back-btn {
  background-color: white;
  width: 40px;
  height: 40px;
}
</style>
